import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";

const AuthCallback = () => {
    const { handleRedirectCallback, isAuthenticated, isLoading } = useAuth0();
    const history = useHistory();

    useEffect(() => {
        const handleAuth = async () => {
            await handleRedirectCallback();  // Auth0のリダイレクト処理を完了
        };
        handleAuth();
    }, [handleRedirectCallback]);

    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            history.push("/profile");  // 認証が成功したらプロフィールにリダイレクト
        }
    }, [isAuthenticated, isLoading, history]);

    return <div>Loading...</div>;
};

export default AuthCallback;
