import { Auth0Provider } from "@auth0/auth0-react";
import ApiComponent from './ApiComponent';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AuthenticationButton from './AuthenticationButton';  // AuthenticationButtonをインポート
import AuthCallback from './AuthCallback'; // コールバック用のコンポーネント
import Profile from './Profile';

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
const reactUrl = `${window.location.origin}`;
const callbackPath = "/auth/callback";
const redirect_uri = `${reactUrl}${callbackPath}`;

function App() {
    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                redirect_uri: redirect_uri,
                audience: audience,
            }}
        >
            <Router>
                <div>
                    <AuthenticationButton />  {/* ログイン・ログアウトボタンを表示 */}
                    <Switch>
                        <Route path="${callbackPath}" component={AuthCallback} />
                        <Route path="/profile" component={Profile} />  {/* Profile を設定 */}
                        <Route path="/" component={ApiComponent} />
                    </Switch>
                </div>
            </Router>
        </Auth0Provider>
    );
}

export default App;
