import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LoginForm from './components/LoginForm';
import AuthCallback from './components/AuthCallback';
import HomePage from './components/HomePage';

import { useAuth0 } from '@auth0/auth0-react';

const AuthenticationButton = () => {
    const { loginWithRedirect, logout, isAuthenticated } = useAuth0();

    return (
        isAuthenticated ? (
            <button onClick={() => logout({ returnTo: window.location.origin })}>
                Log Out
            </button>
        ) : (
            <button onClick={() => loginWithRedirect()}>
                Log In
            </button>
        )
    );
};

export default AuthenticationButton;
